<template>
    <div class="warp">
        <b-container class="navtab">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{$t('message.home')}}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    {{languageName=='cn'?articleObj.cn_title:''}}
                    {{languageName=='en'?articleObj.en_title:''}}
                    {{languageName=='it'?articleObj.it_title:''}}    
                </el-breadcrumb-item>
            </el-breadcrumb>
        </b-container>
        <b-container class="">
            <div class="content">
                <div class="title">
                    {{languageName=='cn'?articleObj.cn_title:''}}
                    {{languageName=='en'?articleObj.en_title:''}}
                    {{languageName=='it'?articleObj.it_title:''}}
                </div>
                <div class="content" v-show="languageName=='cn'" v-html="articleObj.cn_content"></div>
                <div class="content" v-show="languageName=='en'" v-html="articleObj.en_content"></div>
                <div class="content" v-show="languageName=='it'" v-html="articleObj.it_content"></div>
            </div>
        </b-container>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import {yooz_lang} from '@/untils/common';
export default {
    name:'articles',
    data(){
        return{
            languageName:'',
            articleObj:{},
            // [
            //     {
            //         id:28,
            //         title:'我们的外部参与'
            //     },{
            //         id:29,
            //         title:'我们的承诺'
            //     },{
            //         id:17,
            //         title:'监护人计划'
            //     },{
            //         id:3,
            //         title:'非法贸易'
            //     },{
            //         id:24,
            //         title:'保修单'
            //     },{
            //         id:23,
            //         title:'资质认证'
            //     },{
            //         id:31,
            //         title:'联系我们'
            //     },{
            //         id:27,
            //         title:'35 元起免运费'
            //     },{
            //         id:21,
            //         title:'使用条款'
            //     },{
            //         id:22,
            //         title:'隐私政策'
            //     }
            // ]
        }
    },
    watch:{
        searchParams:function() {
            this.getArticleInfo();
        },
    },
    computed:{
        searchParams() {
            if(this.$route.query&&this.$route.query.id){
                var params = {
                    "id":this.$route.query.id
                };
                return params;
            }
        },
    },
    methods: {
        // 文章详情
        getArticleInfo(){
            this.$http.api_article_info(this.searchParams).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.articleObj = res.data;
                        }
                    }else{
                        ElementUI.Message({
                            message:res.message,
                            type:'error',
                            center: true,
                            offset:10,
                        });
                        return false;
                    }
                }
            });
        }
    },
    created(){
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        let routes = this.$route.query;//地址栏参数
        if(routes){
            if(routes.id){
                this.getArticleInfo();// 文章详情
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.navtab{
    padding:20px 10px 10px;
    
}
.content{
    .title{
        font-size:16px;
        text-align: center;
        padding:40px;
    }
    .text{
        
        line-height: 30px;
    }
    .tab{
        margin:20px 0;
        padding:20px;
        width:55%;
        border: 1px solid #eee;
    }
    /deep/img{
        width: 10% !important;
    }

}
/deep/.el-breadcrumb__inner.is-link{
    color: #1f73b7;
    font-weight: normal;
}
/deep/.el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #95959e;
}
</style>